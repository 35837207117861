import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SubMenu = (items) => {
  const subMenu = items.items;
  return (
    <div className="absolute top-10 z-10 hidden bg-red-800  group-hover:block overflow-hidden rounded-md transition-all">
      <div class="px-2 pt-2 pb-4  bg-red-800 text-white shadow-lg ">
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          {subMenu.map((subItem) => (
            <div key={subItem.name}>
              <Link
                to={subItem.link}
                className="block py-2 px-4 text-sm text-white"
              >
                {subItem.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MobileSubMenu = (items) => {
  const subMenu = items.items;
  return (
    <div className="relative hidden group-hover:block overflow-hidden rounded-md transition-all clear-both">
      <div class="px-2 pt-2 pb-4  bg-red-800 text-white shadow-lg border-l-orange-300 border-l-2 md:border-l-0 ">
        <div class="block">
          {subMenu.map((subItem) => (
            <div key={subItem.name}>
              <Link
                to={subItem.link}
                className="clear-both block py-2 px-4 text-sm text-white"
              >
                {subItem.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Nav = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <Disclosure as="header">
      {({ open }) => (
        <>
          <nav
            className="hidden  lg:py-2 lg:flex lg:space-x-8 bg-red-800 "
            aria-label="Global"
          >
            {menuLinks.map((item, i) => {
              const { subMenu } = item;
              const url = item.link;
              console.log(url);
              return (
                <>
                  {!url.includes('://') ? (
                    <Link
                      key={`${i}-${item.name}`}
                      to={`${item.link}`}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300  hover:text-yellow',
                        ' rounded-md py-2 px-3 inline-flex items-center text-sm font-medium group'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                      {subMenu && <SubMenu items={subMenu} />}
                    </Link>
                  ) : (
                    <a
                      key={`${i}-${item.name}`}
                      href={item.link}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300  hover:text-yellow',
                        'rounded-md py-2 px-3 inline-flex items-center text-sm font-medium group'
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </>
              );
            })}
          </nav>

          <Disclosure.Panel
            as="nav"
            className=" bg-red-800 w-screen fixed left-0 p-4 md:hidden min-h-screen"
            aria-label="Global"
          >
            {menuLinks.map((item, i) => {
              const { subMenu } = item;
              const url = item.link;
              return (
                <div className="block" key={`${i}-${item.name}`}>
                  {!url.includes('//') ? (
                    <Link
                      key={`${i}-${item.name}`}
                      to={item.link}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300  hover:text-yellow',
                        ' md:pr-0 rounded-md py-2 md:px-3 flex items-center text-sm font-medium group'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                      {subMenu && <MobileSubMenu items={subMenu} />}
                    </Link>
                  ) : (
                    <a
                      key={`${i}-${item.name}`}
                      href={item.link}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300  hover:text-yellow',
                        'rounded-md py-2 px-3 inline-flex items-center text-sm font-medium group'
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </div>
              );
            })}
            <div className="bg-gray-900 p-4 rounded-md">
              <div className="fl text-center" itemProp="name">
                LaSalle Heating and Air Conditioning Inc. <br />
                <span itemProp="telephone" content="+19524353633">
                  Call Now! <a href="tel:952-435-3633">(952) 435-3633</a>
                </span>
              </div>
              <div
                className="text-center"
                itemProp="address"
                itemScope
                itemType="http://schema.org/PostalAddress"
              >
                <span itemProp="streetAddress">3713 County Rd 42 W </span>,
                <span itemProp="addressLocality">Burnsville </span>,
                <span itemProp="addressRegion">MN </span>
                <span itemProp="postalCode"> 55306</span>
              </div>
            </div>
          </Disclosure.Panel>
          <div className="relative z-10 flex items-center lg:hidden w-full">
            {/* Mobile menu button */}
            <Disclosure.Button className="rounded-md fixed right-10 top-6 p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="sr-only">Open menu</span>
              {open ? (
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
              )}
            </Disclosure.Button>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
              subMenu {
                name
                link
              }
            }
          }
        }
      }
    `}
    render={(data) => <Nav data={data} />}
  />
);
