import React from 'react';
import { Link } from 'gatsby';
import Nav from './Nav';
import logo from '../images/furnace-repair-service.png';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

class Header extends React.Component {
  render(props) {
    return (
      <header className="bg-red-800 text-white pb-5 z-50">
        <div className="max-w-7xl mx-auto p-2 sm:px-4 divide-y-2 divide-white lg:px-8">
          <div className="relative  justify-between hidden md:flex">
            <div className="fl" itemProp="name">
              <span>LaSalle Heating and Air Conditioning Inc. </span>
              <span> | </span>
              <span itemProp="telephone" content="+19524353633">
                Call Now! <a href="tel:952-435-3633">(952) 435-3633</a>
              </span>
            </div>
            <div
              className="fr"
              itemProp="address"
              itemScope
              itemType="http://schema.org/PostalAddress"
            >
              <span itemProp="streetAddress">3713 County Rd 42 W</span>
              <span>, </span>
              <span itemProp="addressLocality">Burnsville</span>
              <span> </span>
              <span itemProp="addressRegion">MN</span>
              <span>, </span>
              <span itemProp="postalCode">55306</span>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-red-700 lg:px-8">
          <div
            className="relative z-10 px-2 md:flex lg:px-0"
            itemProp="ProfessionalService"
            itemScope
            itemType="http://schema.org/LocalBusiness"
          >
            <div className="logo" itemProp="name">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <img
                    className="block h-14 w-auto"
                    alt="LaSalle Heating Cooling Repair Service Minnesota"
                    src={logo}
                    title="LaSalle Heating and Air Conditioning Inc."
                  />
                </Link>
              </div>
            </div>
            <div className="clear-both float-left">
              <Nav {...props} />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
